import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";

const Testimonial = (props) => {
  return (
    <>
      <Box
        sx={{
          mb: 4,
          width: "100%",
          backgroundColor: "background.light",
          p: { xs: 2, md: 20 },
          height: "310px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              mb: 1,
            }}
          >
            <Typography variant="h6">
              {'"' + props.testimonial + '"'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              my: 2,
            }}
          >
            {props.image ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  mr: 2,
                }}
              >
                <Avatar>
                  {props.image ? (
                    <img src={props.image} alt={props.name} width="100%" />
                  ) : (
                    <PersonIcon />
                  )}
                </Avatar>
              </Box>
            ) : (
              ""
            )}
            <Typography variant={"subtitle1"} fontWeight="700">
              {props.who}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Testimonial;