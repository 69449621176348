import {graphql, useStaticQuery} from "gatsby";


const staticData = ()=>{
    const data = useStaticQuery(graphql`
        query GetData {
            allBrandJson {
                nodes {
                    id
                    name
                    briefDescription
                    longDescription
                    logo
                }
            }
            allProductsJson {
                nodes {
                    id
                    description
                    name
                    price
                    order
                }
            }
            allFeaturesJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allSolutionsJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allFaqsJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allStepsJson {
                nodes {
                    id
                    description
                    name
                    order
                }
            }
            allTestimonialsJson {
                nodes {
                    id
                    who
                    testimonial
                }
            }
            websiteJson {
                id
                jsonId
                live {
                    functions {
                        actions
                        form {
                            disabled
                            bottomHeader
                            emailHeader
                            buttonText
                            formHeader
                            forwardTo
                            title
                        }
                        link {
                            buttonText
                            redirectTo
                        }
                        calendly {
                            buttonText
                            redirectTo
                        }
                    }
                    meta {
                        googleAdsConversions {
                            leadConversionId
                            leadLabel
                        }
                    }
                }
            }
            allImageSharp {
                edges {
                    node {
                        id
                        original {
                            src
                            height
                            width
                        }
                        fluid(
                            maxWidth: 1100
                        ) {
                            src
                            srcSet
                            base64
                        }
                        resize(
                            width: 1100
                        ) {
                            src
                        }
                    }
                }
            }
        }
    `)

    return {
        brand : data.allBrandJson.nodes[0],
        products : data.allProductsJson.nodes,
        solutions : data.allSolutionsJson.nodes,
        steps : data.allStepsJson.nodes,
        testimonials : data.allTestimonialsJson.nodes,
        features : data.allFeaturesJson.nodes,
        faqs : data.allFaqsJson.nodes,
        website : data.websiteJson,
        images : data.allImageSharp.edges,
    }

}

export default staticData