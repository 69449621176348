import * as React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BigTile from "../atoms/BigTile";

const Solutions = (props) => {
    return <>
        {props.solutions?.length  !== 0 ?
            <>
                <Stack sx={{
                    textAlign: "center",
                    py:3
                }}>
                    <Typography variant='h2' component='h2' color="primary.light">
                        Solutions
                    </Typography>
                </Stack>
                {
                props.solutions.map((solution, index) => {
                    return <BigTile
                        key={'solution-'+ index}
                        name={solution.name}
                        description={solution.description}
                        image={props.images[index]?.node?.fluid?.src}
                    />
                })
            }
            </>
            : undefined
        }
    </>;
}

export default Solutions