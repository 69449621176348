import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const LargeGridTile = (props) => {
  return (
    <Grid item xs={12} md={6}>
      <Stack
        sx={{
          padding: 4,
          backgroundColor: "background.light",
          minHeight: "132px",
          alignItems: "left",
          borderRadius: 4,
          boxShadow: `0 3px 24px rgb(0 0 0 / 0.02)`
        }}
        spacing={2}
      >
        <Box>
          {props.image ? (
            <>
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>
              <Box>
                <img src={props.image} alt={props.name} width="40px" />
              </Box>
            </>
          ) : null}
          <Typography variant="h4" component="h3">
            {props.name}
          </Typography>
          {props.price ? (
            <Typography variant="h6" fontWeight="bold" color="primary.light">
              {props.price}
            </Typography>
          ) : null}
        </Box>
        <Typography variant="body1" component="p" fontWeight={400}>
          {props.description}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default LargeGridTile;
