import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LargeGridTile from "../atoms/LargeGridTile";

const Faqs = (props) => {
  return (
    <>
      {props.faqs?.length !== 0 ? 
        <>
          <Stack sx={{
                    textAlign: "center",
                    py:3
                }}>
                    <Typography variant='h2' component='h2' color="primary.light">
                        Questions?
                    </Typography>
                </Stack>
         <Box sx={{flexGrow: 1}}>
         <Grid container spacing={3}>
            {props.faqs.map((faq, index) => {
              return (
                <LargeGridTile
                key={"faq-" + index}
                  name={faq.name}
                  description={faq.description}
                />
              );
            })}
          </Grid>
         </Box>
        </>
       : undefined}
    </>
  );
};

export default Faqs;
