import * as React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel';
import Testimonial from "../atoms/Testimonial";

const Testimonials = (props) => {
    
    return <>
        {props.testimonials?.length !== 0 ?

                <>
                    <Stack sx={{
                    textAlign: "center",
                    py:3
                }}>
                    <Typography variant='h2' component='h2' color="primary.light">
                        What customers are saying
                    </Typography>
                </Stack>
                        <Carousel key={'carusel'}
                                  indicators={false}
                                  interval={6000}
                                  navButtonsAlwaysVisible
                                  navButtonsProps={{
                                    style: {
                                        backgroundColor: 'transparent',
                                        color:'gray'
                                    }}}
                                  sx={{
                                    borderRadius: 4,
                                    boxShadow: `0 3px 24px rgb(0 0 0 / 0.02)`}}
                        >
                            {props.testimonials ?
                                props.testimonials.map((testimonial, index) => {
                                    return <Testimonial
                                        key={'testimonial-products-'+ index}
                                        who={testimonial.who}
                                        testimonial={testimonial.testimonial}
                                        image={props.images[index]?.node?.fluid?.src}
                                        index={index}
                                    />
                                })
                                : undefined
                            }
                        </Carousel>
                </>

            : undefined
        }
    </>;
}

export default Testimonials